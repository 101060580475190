import React from "react";
import {
  HashRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "../Pages/Home.js";
import Pricing from "../Pages/Pricing.js";
import Contact from "../Pages/Contact.js";
// import 'react-toastify/dist/ReactToastify.css';

class AppRoutes extends React.Component {
  // state = {
  //   auth: null,
  // }

  // async componentDidMount() {
  //   const auth = await this.getAuth();
  //   this.setState({ auth });
  // }

  // getAuth = () => {
  //   const { token } = this.props;
  //   if (token) {
  //     return true;
  //   } else {
  //     const local_token = localStorage.getItem("access_token");
  //     if (local_token) {
  //       // validate token and set user
  //       return verifyToken({ token: local_token })
  //         .then(async (response) => {
  //           const { data } = response.data;
  //           // new HandleAutoLogout({
  //           //   time: 10 * 60,
  //           //   onTimeOut: () => {
  //           //     localStorage.removeItem("access_token");
  //           //     console.log("Log out trigger");
  //           //     this.props.dispatch({ type: CLEAR_STORE });
  //           //     history?.push("/");
  //           //   },
  //           // });
  //           // set user in store
  //           await this.props.dispatch(mutateUser(data));
  //           return true;
  //         })
  //         .catch((error) => {
  //           localStorage.removeItem("access_token");
  //           return false;
  //         });
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // RequireAuth = ({ children, redirectTo }) => {
  //   let isAuthenticated = this.getAuth();
  //   return isAuthenticated ? children : <Navigate to={"/login"} />;
  // }

  // UserOnlyRoute = ({ children, redirectTo }) => {
  //   const { user } = this.props;
  //   let isAdmin = user?.role === 'admin';
  //   return isAdmin ? <Navigate to={"/gallery"} /> : children ;
  // }

  render() {
    //const { RequireAuth, UserOnlyRoute } = this
    //if (this.state.auth == null) return null;
    return (
      <HashRouter {...this.props}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </HashRouter>
    );
  }
}

export default AppRoutes;