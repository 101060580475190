import React, { useEffect, useState, useRef } from 'react'
// import { Nav } from "react-bootstrap";
// import NavigationBar from "../components/NavigationBar.js";
import Layout from "../components/Layout.js";

const Pricing = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    time: "month",
    price: 399,
    label: "Monthly",
    planId: "abcd"
  });
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const plans = [
    {
      time: "month",
      price: 399,
      label: "Monthly",
      planId: "abcd"
    },
    {
      time: "year",
      price: 3999,
      label: "Yearly",
      planId: "efgh"
    },
    {
      time: "3 year",
      price: 9999,
      label: "3 yearly",
      planId: "ijkl"
    }
  ]

  return (
    <>
      <Layout>
        <section className="pricing main">
          <div className="price-container">
            <div className="box">
              <h1>Explore Mixed Reality for Design and Production</h1>
              <p>
                Download Hive 3D Vision for Apple Vision Pro. By subscribing to
                our monthly plan, you will gain access to the dashboard,
                enabling:
              </p>
              <ul>
                <li>Unlimited uploads of 3D models in USDZ format</li>
                <li>Precision model placement and alignment</li>
                <li>Real-time construction supervision</li>
                <li>Advanced model manipulation tools</li>
                <li>Full access to all app features</li>
              </ul>
            </div>
            <div className="box sub">
              <div className="dropdown left-float" ref={dropdownRef}>
                <a
                  className="drop"
                  href=""
                  onClick={toggleDropdown}
                >
                  {selectedOption.label}
                  <span
                    className={`down-caret ${
                      isDropdownOpen ? "open-caret" : ""
                    }`}
                  ></span>
                </a>
                {isDropdownOpen && (
                  <ul className="dropdown-menu">
                    {plans.map((plan, index)=>(
                          <li key={index}>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelect(plan);
                              }}
                            >
                              {plan.label}
                            </a>
                          </li>
                        )
                      )
                    }
                  </ul>
                )}
              </div>

              <div className="sub-text-box">
                <div className="sub-text">
                  <h2>Hive 3D Vision</h2>
                  <p>
                    Designed for Apple Vision Pro to deliver immersive
                    mixed-reality experiences, high-precision tracking, using
                    Apple’s spatial computing technology. Floating licenses
                    enable seamless access across multiple devices.
                  </p>
                </div>
                <div className="sub-icon">
                  <img src="images/visionpro-icon.png" alt="Vision Pro Icon" />
                </div>
              </div>

              <div className="price-tag">
                <span>€{selectedOption.price}</span>
                per <br />
                {selectedOption.time}
              </div>

              <div className="sub-button">
                <a href="https://app.hive3dvision.com/#/payment" className="button dark">
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Pricing;
