import React from "react";

function Footer() {
  return (
    <footer>
        <div className="footer">
            <div className="about">
                <img src="images/logo-new-black.png" alt=""/>
            </div>

            <ul>
                <li className="title">Product</li>
                <li><a href="https://app.hive3dvision.com">Dashboard</a></li>
                <li><a href="/#/pricing">Pricing</a></li>                
            </ul>

            <ul>
                <li className="title">Help</li>
                <li><a href="/">Docs</a></li>
                <li><a href="/">Submit Project</a></li>                
            </ul>

            <ul>
                <li className="title">The Team</li>
                <li><a href="/">Contact</a></li>
                <li><a href="/">About</a></li>                  
            </ul>

            <ul>
                <li className="title">Legal</li>
                <li><a href="/">Term of Use</a></li>
                <li><a href="/">Privacy Policy</a></li>                
            </ul>
        </div>

    </footer>
  );
}

export default Footer;