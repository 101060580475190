import React from "react";
import NavigationBar from "../components/NavigationBar.js"

const Contact = () => {
    return (
      <>
        <NavigationBar></NavigationBar>
        <div>Hello Hive Contact</div>
      </>
    );
  };
  
  export default Contact;