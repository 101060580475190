import React from "react";
import Layout from "../components/Layout.js";

const Home = () => {
    return (
      <>
        <Layout>
          <section className="home main"></section>
        </Layout>
      </>
    );
  };
  
  export default Home;