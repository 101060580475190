// import React from 'react';
// import NavigationBar from './NavigationBar';

// const Layout = ({ children }) => {
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         {/* Full-width Navigation Bar */}
//         <NavigationBar />

//         {/* Main Content Area */}
//         <div className="col-12 mx-auto px-0 position-relative">
//           <main className="mt-5">{children}</main>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Layout;

import React from 'react';
import NavigationBar from './NavigationBar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Fixed Navigation Bar */}
      <NavigationBar />

      {/* Main Content Area */}
      <div className="flex-grow-1">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
