import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function NavigationBar() {
  return (
    <Navbar expand="lg" >
      <Container>
        {/* Navbar Toggle for Mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Collapsible Navbar Content */}
        <Navbar.Collapse id="basic-navbar-nav" className="w-100">

          <Nav variant="pills" className='' defaultActiveKey="/">
              <Nav.Item>
                  <NavLink to="/" className="nav-link m-1">
                      {/* <span className="bi bi-archive me-2"></span> */}
                      <span className="ml-2">Hive3dvision</span>
                  </NavLink>
              </Nav.Item>
              <Nav.Item>
                  <NavLink to="/pricing" className="nav-link m-1">
                      {/* <span className="bi bi-archive me-2"></span> */}
                      <span className="ml-2">Pricing</span>
                  </NavLink>
              </Nav.Item>
              <Nav.Item>
                  <NavLink to="https://app.hive3dvision.com" className="nav-link m-1">
                      {/* <span className="bi bi-image me-2"></span> */}
                      <span className="ml-2">Dashboard</span>
                  </NavLink>
              </Nav.Item>
              {/* <Nav.Item>
                  <NavLink to="/contact" className="nav-link m-1">
                      <span className="ml-2">Contact</span>
                  </NavLink>
              </Nav.Item> */}
          </Nav>

          {/* Centered logo */}
          <Navbar.Brand className="mx-auto d-none d-lg-block">
            <img
              src="/images/logo-new.png" // Replace with your logo path
              height="40"
              alt="Logo"
            />
          </Navbar.Brand>

          <ul className="end">
            <li><a href="/">in</a></li>
            <li><a href="/">f</a></li>
            <li><a href="/" className="button">Contact</a></li>            
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
